import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { graphql } from "gatsby"

import Layout from "../components/layout"

const PostContainer = styled.div`
  max-width: 960px;
  width: 100%;
  margin: 0px auto;
`

const PostHeader = styled.div`
  margin-bottom: 1em;
  padding: 1em;
`

const PostDate = styled.p`
  display: block;
`

const PostContent = styled.div`
  padding: 1em;
`

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark

    return (
      <Layout pageTitle={post.frontmatter.title}>
        <PostContainer>
          <PostHeader>
            <h1>{post.frontmatter.title}</h1>
            <PostDate>{post.frontmatter.date}</PostDate>
          </PostHeader>
          <PostContent>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </PostContent>
        </PostContainer>
      </Layout>
    )
  }
}

export default BlogPostTemplate

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        path
        description
      }
    }
  }
`
